import { useEffect } from "react";
import { analytics, logEvent } from "../firebase";  // Import Firebase instance

const useAnalytics = (sectionId, eventName) => {
  useEffect(() => {
    const section = document.getElementById(sectionId);

    if (!section) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logEvent(analytics, "section_view", {
              section_name: eventName
            });
          }
        });
      },
      { threshold: 0.5 } // Fires event when 50% of the section is visible
    );

    observer.observe(section);

    return () => observer.unobserve(section);
  }, [sectionId, eventName]);
};

export default useAnalytics;
