import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get } from 'firebase/database'; // For Realtime Database
import { getAnalytics, logEvent } from "firebase/analytics";

//genzvision@gmail.com
// const firebaseConfig = {
//   apiKey: "AIzaSyAEcA7nNUBzgl5cDrXMmxxP2FYD9UsjYG4",
//   authDomain: "genzvision-8e0d5.firebaseapp.com",
//   databaseURL: "https://genzvision-8e0d5-default-rtdb.firebaseio.com",
//   projectId: "genzvision-8e0d5",
//   storageBucket: "genzvision-8e0d5.appspot.com",
//   messagingSenderId: "241744118133",
//   appId: "1:241744118133:web:064ddbafb0a7805f16533c"
// };
//support@genzvision.com
const firebaseConfig = {
  apiKey: "AIzaSyAQUma8m7V_U4LV-OHsumfOuk8TgliaLQs",
  authDomain: "genzvision-f15fd.firebaseapp.com",
  databaseURL: "https://genzvision-f15fd-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "genzvision-f15fd",
  storageBucket: "genzvision-f15fd.firebasestorage.app",
  messagingSenderId: "652063756630",
  appId: "1:652063756630:web:6c5be7f81aaca2c16006f7",
  measurementId: "G-QN15R3MLJT"
};

const app = initializeApp(firebaseConfig);
// For Realtime Database:
const db = getDatabase(app);
export { analytics, logEvent };
// For Firestore:
const analytics = getAnalytics(app);
export { db };